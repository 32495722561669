<template>
  <base-page-layout title="Usuário" :loading="$apollo.loading">
    <router-view />
  </base-page-layout>
</template>
<script>
import { QUERY_GET_INTERNAL_USER } from '@/modules/users/graphql'

export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout.vue')
  },
  data: () => ({
    tab: 0,
    userId: '',
    multiUser: false
  }),
  apollo: {
    singleInternalUser: {
      query: QUERY_GET_INTERNAL_USER,
      fetchPolicy: 'network-only',
      variables() {
        return { id: this.userId }
      },
      update({ singleInternalUser }) {
        this.multiUser = Boolean(singleInternalUser?.multi_user)
      }
    }
  },
  mounted() {
    this.userId = this.$route.params.id
  }
}
</script>
