import gql from 'graphql-tag'

export const QUERY_GET_INTERNAL_USERS = gql`
  query authUsers($page: Int, $pageSize: Int, $search: String, $sort: [String], $sortDirection: [SortDirection]) {
    authUsers(page: $page, pageSize: $pageSize, search: $search, sort: $sort, sortDirection: $sortDirection) {
      count
      data {
        _id
        id_user
        email
        password
        name
        active
        emailConfirmed
        created_at
        updated_at
        accounts {
          id
          isFranchise
        }
        access {
          type
          hasAccess
          management {
            accessGivenAt
            accessGivenBy
          }
          requestedAccess
          requestedAccessAt
        }
        devices {
          fingerprint
          userAgent
          os
          locationCity
          ipAddress
          createdAt
          updatedAt
          verified
          isBlocked
        }
        deviceConfirmation {
          code
          token
          expire_at
          fingerprint
          origin
        }
        permissions {
          ecommerce {
            permissions
            roles
          }
          crm {
            permissions
            roles
            accessSensitveData
            multiUser
          }
          storeAccess {
            crm
            ecommerce
          }
          stores {
            id
            club
            ecommerce
          }
        }
      }
    }
  }
`

export const MUTATION_UPDATE_ACTIVE_USER = gql`
  mutation toggleUserActive($login: String!) {
    toggleUserActive(login: $login) {
      success
    }
  }
`

export const MUTATION_SAVE_INTERNAL_USER = gql`
  mutation(
    $name: String!
    $login: String!
    $password: String!
    $mf: Boolean!
    $admin: Boolean!
    $active: Boolean!
    $listable: Boolean!
    $pages: [String]
    $id_account: String
    $accounts: [ID]
  ) {
    adminAddInternalUser(
      name: $name
      login: $login
      password: $password
      mf: $mf
      admin: $admin
      active: $active
      listable: $listable
      pages: $pages
      id_account: $id_account
      accounts: $accounts
    )
  }
`
export const QUERY_GET_ACCOUNTS_FOR_USERS = gql`
  query getAccountsForUsers($input: GetAccountsForUsersInput!) {
    getAccountsForUsers(input: $input) {
      _id
      name
      db_name
      is_franchise
    }
  }
`

export const MUTATION_UPDATE_USER = gql`
  mutation($id: ID!, $active: Boolean, $mf: Boolean, $multi_user: Boolean, $listable: Boolean, $id_account: ID, $accounts: [String]) {
    updateUser(id: $id, active: $active, mf: $mf, multi_user: $multi_user, listable: $listable, id_account: $id_account, accounts: $accounts)
  }
`
export const MUTATION_ADD_ACCOUNT = gql`
  mutation($id: ID!, $accounts: [ID]) {
    userAddAccounts(id: $id, accounts: $accounts)
  }
`
export const MUTATION_REMOVE_ACCOUNT = gql`
  mutation($id: ID!, $account: ID) {
    userRemoveAccounts(id: $id, account: $account)
  }
`
export const MUTATION_ADD_UNITS = gql`
  mutation($email: String!, $units: [AddUnitsInput!]) {
    userAddUnits(email: $email, units: $units)
  }
`
export const MUTATION_REMOVE_UNITS = gql`
  mutation($id: ID!, $unit: String) {
    userRemoveUnits(id: $id, unit: $unit)
  }
`
export const QUERY_SEARCH_USERS = gql`
  query($param: String) {
    searchUser(param: $param) {
      login
    }
  }
`
export const MUTATION_COPY_FROM_USER = gql`
  mutation($id: ID!, $login: String!, $copyTypes: [String!]) {
    copyFromUser(id: $id, login: $login, copyTypes: $copyTypes)
  }
`
export const QUERY_SEARCH_ACCOUNTS = gql`
  query($param: String) {
    userSearchAccounts(param: $param) {
      _id
      db_name
    }
  }
`
export const QUERY_GET_INTERNAL_USER = gql`
  query($id: ID!) {
    singleInternalUser(id: $id) {
      _id
      id_user
      email
      password
      name
      active
      emailConfirmed
      created_at
      updated_at
      access {
        type
        hasAccess
        management {
          accessGivenAt
          accessGivenBy
        }
        requestedAccess
        requestedAccessAt
      }
      accounts {
        id
      }
      permissions {
        ecommerce {
          permissions
          roles
        }
        crm {
          permissions
          roles
          accessSensitveData
          multiUser
        }
        storeAccess {
          crm
          ecommerce
        }
        stores {
          id
          club
          accountId
          ecommerce
        }
      }
    }
  }
`
export const MUTATION_UPDATE_USERS_PAGE = gql`
  mutation($id: ID!, $page: String!, $flag: String!) {
    updateUserPage(id: $id, page: $page, flag: $flag)
  }
`
